<template  >
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card no-body>
      <div style="background-color:white !important;border-radius: 5px !important;">
        <b-row>
          <b-col>
            <b-table
              :striped="false"
              :bordered="true"
              :hover="true"
              :busy="show"
              :small="true"
              :items="listDataAll"
              :fields="translatedTableColumns"
              primary-key="id"
              head-variant="dark"
              responsive
              :sticky-header="stickyHeader"
            >
              <template #cell(DOKME)="data">
                <div class="d-flex justify-content-right" v-if="data.item.STATU=='AKTIF'">
                  <!-- <b-form-spinbutton
                    v-if="dokmePaletDisabled==false"
                    style="background-color:yellow"
                    :style="`background-color:${getRenkBGDOKME(data.item.DOKME)}`"
                    v-model="data.item.DOKME"
                    size="sm"
                    min="0"
                    max="10000000"
                    :step="data.item.packageUnit"
                    class="ml-75"
                    inline
                    @change="handleInputChange('DOKME')"
                  />
                  -->
                  <b-form-input
                    class="text-center"
                    v-if="dokmePaletDisabled==false"
                    v-model="data.item.DOKME"
                    type="number"
                    :step="data.item.packageUnit"
                    @change="handleInputChange('DOKME')"
                  />
                </div>
              </template>
              <template #cell(PALLET20DC)="data">
                <div class="d-flex justify-content-center" v-if="data.item.STATU=='AKTIF'">
                  <b-form-spinbutton
                    style="background-color:yellow"
                    :style="`background-color:${getRenkBG20DC(data.item.PALLET20DC)}`"
                    v-model="data.item.PALLET20DC"
                    v-if="palet20dcDisabled==false"
                    size="sm"
                    min="0"
                    max="10000000"
                    :step="data.item.packageUnit"
                    class="ml-75"
                    inline
                    @change="handleInputChange('PALLET20DC')"
                  />
                </div>
              </template>
              <template #cell(PALLETREEFER40HQ)="data">
                <div class="d-flex justify-content-center" v-if="data.item.STATU=='AKTIF'">
                  <b-form-spinbutton
                    v-if="palet40sogutmaDisabled==false"
                    v-model="data.item.PALLETREEFER40HQ"
                    :style="`background-color:${getRenkBG40HQ(data.item.PALLETREEFER40HQ)}`"
                    size="sm"
                    min="0"
                    max="10000000"
                    :step="data.item.packageUnit"
                    class="ml-75"
                    inline
                    @change="handleInputChange('PALLETREEFER40HQ')"
                  />
                </div>
              </template>
              <template #cell(PALLET40HQ)="data">
                <div class="d-flex justify-content-center" v-if="data.item.STATU=='AKTIF'">
                  <b-form-spinbutton
                    v-model="data.item.PALLET40HQ"
                    v-if="palet40hqDisabled==false"
                    :style="`background-color:${getRenkBG40(data.item.PALLET40HQ)}`"
                    size="sm"
                    min="0"
                    max="10000000"
                    :step="data.item.packageUnit"
                    class="ml-75"
                    inline
                    @change="handleInputChange('PALLET40HQ')"
                  />
                </div>
              </template>
              <template #cell(PALLET45HQ)="data">
                <div class="d-flex justify-content-center" v-if="data.item.STATU=='AKTIF'">
                  <b-form-spinbutton
                    v-model="data.item.PALLET45HQ"
                    v-if="palet45Disabled==false"
                    :style="`background-color:${getRenkBG45(data.item.PALLET45HQ)}`"
                    size="sm"
                    min="0"
                    max="10000000"
                    :step="data.item.packageUnit"
                    class="ml-75"
                    inline
                    @change="handleInputChange('PALLET45HQ')"
                  />
                </div>
              </template>
              <template #head(imgViewer)>
                <b-col></b-col>
              </template>
              <template #head(KOD)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Kod')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #cell(imgViewer)="data">
                <div class="d-flex justify-content-center" style="padding:0px;margin:0px">
                  <b-button
                    style="background-color:white !important;padding:0px;border:0px;margin:0px"
                  >
                    <b-img
                      :src="data.item.LINK"
                      style="width:90px;height:90px"
                      v-b-modal.modal-center
                      @click="showImageClick(data)"
                    />
                  </b-button>
                </div>
              </template>
              <template #head(AD)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Ad')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #cell(AD)="data">
                <b-col>
                  <b-row>
                    <p style="color:black">{{ data.item.AD}}</p>
                  </b-row>
                  <b-row>
                    <!-- burayı düzelticem -->

                    <b-button
                      @click="yeniTeklifiste(data.item)"
                      variant="outline-danger"
                      size="sm"
                      v-if="data.item.STATU=='GECMIS'"
                    >{{$t('Yeni Fiyat İsteyiniz')}}</b-button>
                    <p
                      v-if="data.item.STATU=='TALEP'||data.item.STATU=='ONAY'"
                      style="color:orange"
                    >{{$t('Fiyat Bekleniyor')}}..</p>
                    <p
                      v-if="data.item.SONKACGUN<=7&&data.item.STATU=='AKTIF'"
                      style="color:red"
                    >{{$t('Geçerli fiyat için son:')}} {{data.item.SONKACGUN}} {{$t('gün')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #cell(KOD)="data">
                <b-row>
                  <b-col>
                    <p class="text-center" style="color:black">{{ data.item.KOD}}</p>
                  </b-col>
                </b-row>
                <b-row v-if="data.item.MUSTERIURUNKOD!=''">
                  <b-col>
                    <p>({{ data.item.MUSTERIURUNKOD}})</p>
                  </b-col>
                </b-row>
              </template>
              <template #head(P1)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>
                      {{ $t('Palet')}}
                      <br />(80x120) 20DC
                      <br />
                      {{ $t('Koli')}}
                    </p>
                  </b-row>
                </b-col>
              </template>
              <template #head(P2)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>
                      {{ $t('Palet')}}
                      <br />(80x120) 40HQ
                      <br />
                      {{ $t('Koli')}}
                    </p>
                  </b-row>
                </b-col>
              </template>
              <template #head(EN)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('En')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>(mm)</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(BOY)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Boy')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>(mm)</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(YUKSEKLIK)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Yükseklik')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>(mm)</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(M3)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('M3')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(SHOWBRUT)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Brüt')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(SHOWNET)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Net')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(ADET_MIKTAR)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Adet')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(PRICEDIT)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('Fiyatı')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(DOKME)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('DOKME')}}</p>
                  </b-row>
                </b-col>
              </template>
              <template #head(PALLET20DC)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('PALET 20DC')}}</p>
                  </b-row>

                  <b-row style="background-color:white;text-align:center;margin-top:-10px">
                    <b-col style="text-align:center;justify-content:center">
                      <b-row style="text-align:center;justify-content:center">
                        <h6
                          style="color:black;margin-top:3px;text-align:center;"
                        >{{Toppallet20dc}}/{{pallet20dc}}</h6>
                      </b-row>
                      <b-row style="text-align:center;justify-content:center;margin-top:-10px;">
                        <h6 style="color:black;margin-top:0px">{{ $t('Palet')}}</h6>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>

                <b-progress
                  class="mt-0"
                  :max="Toppallet20dc"
                  show-value
                  height="20px"
                  style="border-radius: 0px;"
                >
                  <b-progress-bar :value="pallet20dc" variant="warning" style="border-radius: 0px;"></b-progress-bar>
                </b-progress>
              </template>
              <template #head(PALLETREEFER40HQ)>
                <b-col>
                  <b-row id="headerText">
                    <p>{{ $t('REEFER')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('PALLET 40HQ')}}</p>
                  </b-row>

                  <b-row style="background-color:white;text-align:center;margin-top:-10px">
                    <b-col style="text-align:center;justify-content:center">
                      <b-row style="text-align:center;justify-content:center">
                        <h6
                          style="color:black;margin-top:3px;text-align:center;"
                        >{{Toppalletref40hq}}/{{palletref40hq}}</h6>
                      </b-row>
                      <b-row style="text-align:center;justify-content:center;margin-top:-10px;">
                        <h6 style="color:black;margin-top:0px">{{ $t('Palet')}}</h6>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-progress
                  class="mt-0"
                  :max="Toppalletref40hq"
                  show-value
                  height="20px"
                  style="border-radius: 0px;"
                >
                  <b-progress-bar
                    :value="palletref40hq"
                    variant="warning"
                    style="border-radius: 0px;"
                  ></b-progress-bar>
                </b-progress>
              </template>
              <template #head(PALLET40HQ)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('PALET 40HQ')}}</p>
                  </b-row>

                  <b-row style="background-color:white;text-align:center;margin-top:-10px">
                    <b-col style="text-align:center;justify-content:center">
                      <b-row style="text-align:center;justify-content:center">
                        <h6
                          style="color:black;margin-top:3px;text-align:center;"
                        >{{Toppallet40hq}}/{{pallet40hq}}</h6>
                      </b-row>
                      <b-row style="text-align:center;justify-content:center;margin-top:-10px;">
                        <h6 style="color:black;margin-top:0px">{{ $t('Palet')}}</h6>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-progress
                  class="mt-0"
                  :max="Toppallet40hq"
                  show-value
                  height="20px"
                  style="border-radius: 0px;"
                >
                  <b-progress-bar
                    :value=" pallet40hq"
                    variant="warning"
                    style="border-radius: 0px;"
                  ></b-progress-bar>
                </b-progress>
              </template>
              <template #head(PALLET45HQ)>
                <b-col>
                  <b-row id="headerText">
                    <p style="color:#4B4B4B">{{ $t('.')}}</p>
                  </b-row>
                  <b-row id="headerText" style="margin-top:-15px">
                    <p>{{ $t('PALET 45HQ')}}</p>
                  </b-row>

                  <b-row style="background-color:white;text-align:center;margin-top:-10px">
                    <b-col style="text-align:center;justify-content:center">
                      <b-row style="text-align:center;justify-content:center">
                        <h6
                          style="color:black;margin-top:3px;text-align:center;"
                        >{{Toppallet45hq}}/{{pallet45hq}}</h6>
                      </b-row>
                      <b-row style="text-align:center;justify-content:center;margin-top:-10px;">
                        <h6 style="color:black;margin-top:0px;">{{ $t('Palet')}}</h6>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-progress
                  class="mt-0"
                  :max="Toppallet45hq"
                  show-value
                  height="20px"
                  style="border-radius: 0px;"
                >
                  <b-progress-bar :value="pallet45hq" variant="warning" style="border-radius: 0px;"></b-progress-bar>
                </b-progress>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row style="margin-top:10px">
          <b-col>
            <b-card style="width:100%;max-height:10px !important"></b-card>
          </b-col>
        </b-row>

        <b-modal id="modal-center" centered :hide-footer="true" size="lg">
          <b-row>
            <b-col>
              <b-row>
                <b-col center>{{ $t('Popupimg')}}</b-col>
              </b-row>
              <b-row>
                <b-col cols="2"></b-col>
                <b-col cols="8" style="float:center !important">
                  <b-img :src="selectedImgUrl" center fluid />
                </b-col>
                <b-col cols="2"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal v-model="createSipModal" centered :hide-footer="true" size="lg">
          <b-overlay
            variant="white"
            :show="show"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <b-row>
              <b-col>
                <b-row>
                  <b-col cols="3"></b-col>
                  <b-col style="text-align:center">
                    <p>{{ $t('Teslim Tarihi')}}</p>
                    <datepicker
                      v-model="teslimTarihi"
                      :language="languages.tr"
                      :disabled-date="disabledStartDate"
                      monday-first
                      style="width:100%"
                    ></datepicker>
                    <p style="margin-top:5px;text-align:left">{{ $t('Zorunlu Alan')}}</p>
                  </b-col>
                  <b-col cols="3"></b-col>
                </b-row>
                <b-row style="margin-top:30px">
                  <b-col style="text-align:center">
                    <p>{{ $t('createOrderPopup')}}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-button
                      type="button"
                      variant="danger"
                      style="width:95%;color:white !important"
                      @click="cancelOrder()"
                    >{{ $t('İptal')}}</b-button>
                  </b-col>
                  <b-col cols="6">
                    <b-button
                      type="button"
                      variant="success"
                      style="width:95%;color:white !important"
                      @click="cariSec()"
                    >{{ $t('Evet')}}</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </b-modal>

        <b-modal v-model="errorModal" centered :hide-footer="true" size="lg" :title="$t('Hatalı')">
          <b-row>
            <b-col style="text-align:center">
              <h5>{{hataliKod}}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="text-align:center;margin-top:20px">
              <h5>{{ $t('Palet Miktarı 0 Olamaz')}}</h5>
            </b-col>
          </b-row>
          <b-row style="margin-top:30px">
            <b-col>
              <b-button
                type="button"
                variant="danger"
                style="width:100%;color:white !important"
                @click="closeModal()"
              >{{ $t('Tamam')}}</b-button>
            </b-col>
          </b-row>
        </b-modal>

        <b-row class="row-fixed">
          <b-card class="fixed-card">
            <b-row>
              <b-col cols="8" class="mt-1">
                <b-row>
                  <b-col cols="7">
                    <b-row>
                      <b-col cols="4">
                        <b-row>
                          <b-col cols="7">
                            <h5>
                              <b>{{ $t('Toplam Koli')}}</b>
                            </h5>
                          </b-col>
                          <b-col cols="5" style="text-align:right">
                            <h5>{{toplamKoli}}</h5>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="4">
                        <b-row>
                          <b-col cols="7">
                            <h5>
                              <b>{{ $t('Toplam M3')}}</b>
                            </h5>
                          </b-col>
                          <b-col cols="5" style="text-align:right">
                            <h5>{{toplamM3}}</h5>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="4">
                        <b-row>
                          <b-col cols="7">
                            <h5>
                              <b>{{ $t('Toplam Brüt')}}</b>
                            </h5>
                          </b-col>
                          <b-col cols="5" style="text-align:right">
                            <h5>{{toplamBrut}}</h5>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="1"></b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col cols="5">
                        <h4>
                          <b>{{ $t('Toplam Tutar')}}</b>
                        </h4>
                      </b-col>
                      <b-col cols="7" style="text-align:right">
                        <h4 style="color:black">{{toplamTutar}} {{tutarBirimi}}</h4>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="2">
                <b-button
                  class="w-100"
                  v-if="filter==='Filtre'"
                  type="button"
                  variant="primary"
                  @click="onFiltered('Tümü')"
                >
                  <h5>
                    <feather-icon size="15" style="color:black !important" icon="ListIcon" />
                    {{ $t('Hepsi')}}
                  </h5>
                </b-button>
                <b-button
                  class="w-100"
                  v-if="filter==='Tümü'"
                  type="button"
                  variant="primary"
                  @click="onFiltered('Filtre')"
                >
                  <h5>
                    <feather-icon size="15" style="color:black !important" icon="ShoppingCartIcon" />
                    {{ $t('Seçilen Ürünler')}} ({{seciliurunlersay}})
                  </h5>
                </b-button>
              </b-col>
              <b-col cols="2">
                <b-button
                  class="w-100"
                  style="color:white !important"
                  type="button"
                  variant="success"
                  @click="createProforma()"
                >
                  <h5 style="color:white !important">
                    <feather-icon size="16" style="color:white !important;" icon="PrinterIcon" />
                    {{ $t('Proforma Oluştur')}}
                  </h5>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
      </div>
    </b-card>
    <b-modal v-model="tModal" :title="$t('Teklif İste')" hide-footer>
      <p class="text-center mt-3">{{$t('Fiyat Teklifi Almak İstediğinize Emin misiniz')}}</p>
      <b-row>
        <b-col cols="6">
          <b-button
            class="mt-2 w-48"
            style="float:left"
            variant="danger"
            block
            @click="closePopup()"
          >{{$t('İptal')}}</b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            class="mt-2 w-48"
            style="float:right"
            variant="success"
            block
            @click="teklifSend()"
          >{{$t('Evet')}}</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="cariModal"
      :title="$t('Sevkiyat Hesabı Seç')"
      centered
      :hide-footer="true"
      size="lg"
    >
      <b-row class="mt-3">
        <b-col>
          <b-form-select
            :label="this.$t('Sevkiyat Hesabı Seç')"
            v-model="seciliSevkAdresi"
            :options="sevkAdresleri"
            class="mb-3"
            value-field="SEVKIYATKOD"
            text-field="SEVKIYATAD"
            disabled-field="notEnabled"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="9"></b-col>
        <b-col cols="3">
          <b-button type="button" style="width:95%;" variant="success" @click="createOrder()">
            <h5 style="color:white !important">
              <feather-icon size="16" style="color:white !important;" icon="ArrowRightIcon" />
              {{ $t('ileri')}}
            </h5>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="rafModal" :title="$t('RafOmruSeç')" centered :hide-footer="true" size="lg">
      <b-row class="mt-1">
        <b-col>
          <b-table
            :striped="false"
            :bordered="true"
            :hover="true"
            :busy="show"
            :small="true"
            :items="sendMacroData.satirBilgileri"
            :fields="translatedColumns"
            primary-key="id"
            head-variant="dark"
            responsive
            :sticky-header="stickyHeader"
          >
            <template #cell(DOKME)="data">
              <div class="d-flex justify-content-center" v-if="data.item.STATU=='AKTIF'">
                <b-form-input
                  v-if="dokmePaletDisabled==false"
                  v-model="data.item.DOKME"
                  type="number"
                  :step="data.item.packageUnit"
                  @change="handleInputChange('DOKME')"
                />
              </div>
            </template>

            <template #head(KOD)>
              <b-col>
                <b-row id="headerText">
                  <p style="color:#4B4B4B">{{ $t('.')}}</p>
                </b-row>
                <b-row id="headerText" style="margin-top:-15px">
                  <p>{{ $t('Kod')}}</p>
                </b-row>
              </b-col>
            </template>
            <template #head(AD)>
              <b-col>
                <b-row id="headerText">
                  <p style="color:#4B4B4B">{{ $t('.')}}</p>
                </b-row>
                <b-row id="headerText" style="margin-top:-15px">
                  <p>{{ $t('Ad')}}</p>
                </b-row>
              </b-col>
            </template>
            <template #head(RAFOMRU)>
              <b-col>
                <b-row id="headerText">
                  <p style="color:#4B4B4B">{{ $t('.')}}</p>
                </b-row>
                <b-row id="headerText" style="margin-top:-15px">
                  <p>{{ $t('Raf Ömrü')}}</p>
                </b-row>
              </b-col>
            </template>
            <template #cell(RAFOMRU)="data">
              <div class="d-flex justify-content-center">
                <b-form-select v-model="data.item.RAFOMRU" :options="rafOmurleri" />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="5"></b-col>
        <b-col cols="2">
          <b-button type="button" style="width:95%;" variant="success" @click="onayla()">
            <h5 style="color:white !important">
              <feather-icon size="16" style="color:white !important;" icon="ArrowRightIcon" />
              {{ $t('ileri')}}
            </h5>
          </b-button>
        </b-col>
        <b-col cols="5"></b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BFormSelect,
  BCard,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
  BFormInput,
  BOverlay,
  BModal,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BFormRadioGroup,
  BFormGroup,
  BCol,
  BTable,
  BFormFile,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BProgress,
  BProgressBar
} from "bootstrap-vue";
import { t } from "../@core/libs/i18n/utils";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
import Datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { forEach } from "postcss-rtl/lib/affected-props";
function formatSayi(sayi, ozellestirilmisSecenekler) {
  return new Intl.NumberFormat(undefined, ozellestirilmisSecenekler).format(
    sayi
  );
}
export default {
  components: {
    BFormSelect,
    vSelect,
    BFormCheckbox,
    BCard,
    BCardText,
    BLink,
    BBadge,
    BDropdown,
    BImg,
    BFormSpinbutton,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BModal,
    BCardHeader,
    BCardBody,
    BRow,
    BButton,
    BFormRadioGroup,
    BFormGroup,
    BCol,
    BTable,
    BFormFile,
    BMedia,
    BAvatar,
    moment,
    lang,
    Datepicker,
    BProgress,
    BProgressBar
  },
  computed: {
    filteredDokme() {
      if (this.toplamDokme > 0) {
        this.palet45Disabled = true;
        this.palet40sogutmaDisabled = true;
        this.palet40hqDisabled = true;
        this.palet20dcDisabled = true;
      } else {
        this.palet45Disabled = false;
        this.palet40sogutmaDisabled = false;
        this.palet40hqDisabled = false;
        this.palet20dcDisabled = false;
      }
    },
    filteredPalet20() {
      if (this.pallet20dc > 0) {
        this.dokmePaletDisabled = true;
        this.palet40sogutmaDisabled = true;
        this.palet40hqDisabled = true;
        this.palet45Disabled = true;
      } else {
        this.dokmePaletDisabled = false;
        this.palet40sogutmaDisabled = false;
        this.palet40hqDisabled = false;
        this.palet45Disabled = false;
      }
    },
    filtered40Hq() {
      if (this.pallet40hq > 0) {
        this.palet45Disabled = true;
        this.palet40sogutmaDisabled = true;
        this.dokmePaletDisabled = true;
        this.palet20dcDisabled = true;
      } else {
        this.palet45Disabled = false;
        this.palet40sogutmaDisabled = false;
        this.dokmePaletDisabled = false;
        this.palet20dcDisabled = false;
      }
    },
    filteredsogutmali40Hq() {
      if (this.palletref40hq > 0) {
        this.palet45Disabled = true;
        this.palet40hqDisabled = true;
        this.dokmePaletDisabled = true;
        this.palet20dcDisabled = true;
      } else {
        this.palet45Disabled = false;
        this.palet40hqDisabled = false;
        this.dokmePaletDisabled = false;
        this.palet20dcDisabled = false;
      }
    },

    filteredPalet45() {
      if (this.pallet45hq > 0) {
        this.dokmePaletDisabled = true;
        this.palet40sogutmaDisabled = true;
        this.palet40hqDisabled = true;
        this.palet20dcDisabled = true;
      } else {
        this.dokmePaletDisabled = false;
        this.palet40sogutmaDisabled = false;
        this.palet40hqDisabled = false;
        this.palet20dcDisabled = false;
      }
    },
    // ceviri kısmının calisma sekli
    translatedTableColumns() {
      return this.tableColumns.map(column => {
        return { ...column, label: this.$t(column.label) };
      });
    },
    translatedColumns() {
      return this.tableColumns2.map(column => {
        return { ...column, label: this.$t(column.label) };
      });
    }
  },
  data() {
    return {
      //kolonların devredısı kalmasi
      dokmePaletDisabled: false,
      palet20dcDisabled: false,
      palet40sogutmaDisabled: false,
      palet40hqDisabled: false,
      palet45Disabled: false,
      //kolonların devredısı kalmasi
      ozellestirilmisSecenekler: {
        minimumFractionDigits: 2, // küsürat kısmının minimum hane sayısı
        maximumFractionDigits: 2, // küsürat kısmının maksimum hane sayısı
        minimumIntegerDigits: 2, // tam sayı kısmının minimum hane sayısı
        useGrouping: true // binlik grup ayırıcıları kullanılsın mı?
      },
      tutarBirimi: "",
      toplamTutar: 0,
      toplamKoli: 0,
      toplamM3: 0,
      toplamBrut: 0,
      seciliurunlersay: 0,
      htmlTemp: "",
      rowSelected: [],
      Toppallet20dc: 11,
      pallet20dc: 0,
      Toppalletref40hq: 23,
      palletref40hq: 0,
      Toppallet40hq: 24,
      pallet40hq: 0,
      Toppallet45hq: 33,
      pallet45hq: 0,
      toplamDokme: 0,
      listData: [],
      listDataAll: [],
      selectedImgUrl: "",
      currentPage: 1,
      perPage: 7,
      rows: 0,
      show: false,
      userData: "",
      filter: "Tümü",
      stickyHeader: true,
      orderModal: false,
      createSipModal: false,
      errorModal: false,
      hataliKod: "",
      tableColumns2: [
        { key: "KOD", class: "productKod", label: "Kod" },
        { key: "AD", class: "productAd", label: "Ad" },
        { key: "RAFOMRU", class: "productDay", label: "Raf Ömrü" }
      ],
      tableColumns: [
        { key: "KOD", class: "productKod", label: "Kod" },
        {
          key: "imgViewer",
          class: "productButton",
          label: "Resim"
        },
        { key: "AD", class: "productAd", label: "Ad" },
        {
          key: "P1",
          class: "productP1",
          label: "PALLET (80x120) 20DC"
        },
        { key: "P2", class: "productP2", label: "PALLET (80x120) 40HQ" },
        { key: "EN", class: "productEn", label: "En" },
        { key: "BOY", class: "productBoy", label: "Boy" },
        { key: "YUKSEKLIK", class: "productYukseklik", label: "Yükseklik" },
        { key: "M3", class: "productM3", label: "M3" },
        { key: "SHOWBRUT", class: "productBrut", label: "Brüt" },
        { key: "SHOWNET", class: "productNet", label: "Net" },
        { key: "ADET_MIKTAR", class: "productAdet", label: "Adet" },
        {
          key: "PRICEDIT",
          class: "productPrice",
          label: "Fiyatı"
        },
        {
          key: "DOKME",
          class: "dokme",
          label: "DOKME"
        },
        {
          key: "PALLET20DC",
          class: "pallet20DC",
          label: "PALET 20DC"
        },
        {
          key: "PALLETREEFER40HQ",
          class: "palletReefer40HQ",
          label: "PALLET REEFER 40HQ"
        },
        { key: "PALLET40HQ", class: "pallet40HQ", label: "PALET 40HQ" },
        { key: "PALLET45HQ", class: "pallet45HQ", label: "PALET 45HQ" }
      ],
      createdDate: "",
      cariKontak: "",
      teslimTarihi: "",
      languages: lang,
      sendMacroData: {
        evrakBilgileri: {
          totalGross: 0,
          totalNet: 0,
          totalPcs: 0,
          totalCarton: 0,
          totalVolume: 0,
          totalSub: 0,
          totalGrand: 0,
          createdDate: "",
          adres1: "",
          adres2: "",
          telefon: "",
          email: "",
          fax: "",
          musteriAd: "",
          musteriKod: "",
          deliveryDate: "",
          SHIPLOC_CODE: ""
        },
        satirBilgileri: [],
        propSatirlar: []
      },
      selectedProduct: "",
      tModal: false,
      rafOmurleri: [],
      sevkAdresleri: [],
      rafModal: false,
      cariModal: false,
      seciliSevkAdresi: ""
    };
  },

  created() {
    var uData = JSON.parse(localStorage.getItem("B2BUserData"));
    this.userData = uData;
    this.getContact();
    this.rafOmruGetir();

    if (this.$route.query.orders != undefined) {
      if (this.$route.query.orders.length > 0) {
        this.siparisTekrarla();
      }
    } else {
      this.listele();
    }
  },

  methods: {
    async yeniTeklifiste(data) {
      this.selectedProduct = data;
      this.tModal = true;
    },
    async teklifSend() {
      var jsonmodel = {
        MUSTERIADI: this.userData.musteriAd,
        MUSTERIKODU: this.userData.musteriKod,
        AD: this.selectedProduct.AD,
        KOD: this.selectedProduct.KOD,
        PREPRICE: this.selectedProduct.PRICE,
        PRICE: 0,
        PRICEUNIT: this.selectedProduct.PRICEUNIT.trim(),
        KRI_TARIH_1: "",
        KRI_TARIH_2: "",
        TRNUM: "",
        EVRAKNO: "",
        DURUM: 4,
        SF_UNIT: this.selectedProduct.SF_UNIT.trim(),
        SEVKIYATHESABI: "",
        KANAL: this.userData.kanal
      };
      var jsonconvert = JSON.stringify(jsonmodel);

      this.show = true;
      try {
        var request = {
          FunctionName: "d7kRun",
          MacroName: "D7KSUP007",
          JsonParams: jsonconvert,
          MacroKey: "FiyatListesiOlustur"
        };
        await this.$http
          .post("Com/RunMacroWithAuth", request)
          .then(response => {
            if (response.data.succeeded == true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("Basarili"),
                  icon: "BellIcon",
                  text: this.$t("Teklif Talebiniz İletildi."),
                  variant: "success"
                }
              });
              this.tModal = false;

              this.selectedProduct.STATU = "TALEP";

              this.show = false;
            } else {
              var response0 = JSON.parse(response.data.data);
              if (response0.message == "20240201165701") {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t(
                      "Ürün için fiyat Talebiniz onay aşamasında, süreç tamamlanana kadar, Tekrar talep geçilemez.!"
                    )
                  }
                });
              } else if (response0.message == "20240201165702") {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t(
                      "Ürün için fiyat Talebiniz alınmıştır, Tekrar talep geçilemez.!"
                    )
                  }
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Hatalı"),
                    icon: "DangerIcon",
                    variant: "danger",
                    text: this.$t("Hatalı İşlem Lütfen Kontrol Ediniz !")
                  }
                });
              }

              this.show = false;
            }
          });
      } catch (ex) {
        this.show = false;
        console.log(ex, "Hata");
      }
    },
    async siparisTekrarla() {
      this.propSatirlar = this.$route.query.orders;
      this.show = true;
      var request = {
        queryKey: "B2B_Urun_Listele",
        args: [
          {
            key: "@KRITER1",
            value: this.userData.musteriKod
          }
        ]
      };

      this.$http
        .post("v1/Dynamic/GetAllWithParams", request)
        .then(async response => {
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);

            for (var a = 0; a < satirlar.length; a++) {
              satirlar[a].EN = formatSayi(satirlar[a].EN);
              satirlar[a].BOY = formatSayi(satirlar[a].BOY);
              satirlar[a].YUKSEKLIK = formatSayi(satirlar[a].YUKSEKLIK);
              satirlar[a].M3 = satirlar[a].M3.toFixed(5);
              satirlar[a].TOPLAMNET = 0;
              satirlar[a].TOPLAMBRUT = 0;
              satirlar[a].TOPLAMM3 = 0;
              satirlar[a].TOPLAMKOLI = 0;
              satirlar[a].PALLET45HQ = 0;
              satirlar[a].PALLET40HQ = 0;
              satirlar[a].PALLETREEFER40HQ = 0;
              satirlar[a].PALLET20DC = 0;
              satirlar[a].DOKME = 0;
              satirlar[a].PRICEDIT =
                satirlar[a].PRICE.toString() + " " + satirlar[a].PRICEUNIT2;
              satirlar[a].LINK =
                "https://document.d7ksuper.com/Dokuman/linkal/ucantay/" +
                satirlar[a].KOD.substring(0, 8) +
                ".png";

              for (var i = 0; i < this.propSatirlar.length; i++) {
                var satir = this.propSatirlar[i];

                if (satirlar[a].KOD == satir.KOD) {
                  satirlar[a].PALLET45HQ = satir.PALLET45HQ;
                  satirlar[a].PALLET40HQ = satir.PALLET40HQ;
                  satirlar[a].PALLETREEFER40HQ = satir.PALLETREEFER40HQ;
                  satirlar[a].PALLET20DC = satir.PALLET20DC;
                  satirlar[a].DOKME = satir.DOKME;
                }
              }
            }

            this.listDataAll = satirlar;
            this.rows = satirlar.length;
            await this.handleInputChange("PALLET20DC");
            await this.handleInputChange("PALLETREEFER40HQ");
            await this.handleInputChange("PALLET40HQ");
            await this.handleInputChange("PALLET45HQ");
            await this.handleInputChange("DOKME");
            this.show = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    disabledStartDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return (
        date < today || date < new Date(today.getTime() + 15 * 24 * 3600 * 1000)
      );
    },
    async clearPage() {
      this.pallet45hq = 0;
      this.pallet40hq = 0;
      this.palletref40hq = 0;
      this.pallet20dc = 0;
      this.seciliurunlersay = 0;
      this.toplamKoli = 0;
      this.toplamTutar = 0;
      this.toplamDokme = 0;
      this.tutarBirimi = "";
      this.teslimTarihi = "";
      this.sendMacroData.satirBilgileri = [];
      this.sendMacroData.evrakBilgileri.totalGross = 0;
      this.sendMacroData.evrakBilgileri.deliveryDate = "";
      this.sendMacroData.evrakBilgileri.fax = "";
      this.sendMacroData.evrakBilgileri.createdDate = "";
      this.sendMacroData.evrakBilgileri.totalGrand = 0;
      this.sendMacroData.evrakBilgileri.totalSub = 0;
      this.sendMacroData.evrakBilgileri.totalVolume = 0;
      this.sendMacroData.evrakBilgileri.totalNet = 0;
      this.sendMacroData.evrakBilgileri.totalPcs = 0;
      this.sendMacroData.evrakBilgileri.totalCarton = 0;
      this.onFiltered("Tümü");
      this.listele();

      for (var i = 0; i < this.listDataAll.length; i++) {
        this.listDataAll[i].PALLET40HQ = 0;
        this.listDataAll[i].PALLET45HQ = 0;
        this.listDataAll[i].PALLET20DC = 0;
        this.listDataAll[i].PALLETREEFER40HQ = 0;
        this.listDataAll[i].DOKME = 0;
      }
    },
    // gönderilen uç
    async createOrder() {
      if (this.seciliSevkAdresi == "" || this.seciliSevkAdresi == null) {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Hatalı"),
            icon: "DangerIcon",
            variant: "danger",
            text: this.$t("Lütfen Ürün Seçiniz !")
          }
        });
        return;
      }
      this.cariModal = false;
      this.sendMacroData.evrakBilgileri.SHIPLOC_CODE = this.seciliSevkAdresi;
      this.sendMacroData.evrakBilgileri.deliveryDate = moment(
        this.teslimTarihi
      ).format("YYYY/MM/DD");

      this.show = true;
      try {
        var request = {
          FunctionName: "d7kRun",
          MacroName: "D7KSUP007",
          JsonParams: JSON.stringify(this.sendMacroData),
          MacroKey: "B2bSiparisOlustur"
        };

        await this.$http
          .post("Com/RunMacroWithAuth", request)
          .then(response => {
            if (response.data.succeeded == true) {
              this.clearPage();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("Basarili"),
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: this.$t("Siparis Basarili Sekilde Olusturuldu")
                }
              });
              this.show = false;
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Hatalı"),
                  icon: "DangerIcon",
                  variant: "danger",
                  text: this.$t("Hatalı İşlem Lütfen Kontrol Ediniz !")
                }
              });

              this.show = false;
            }
          });
      } catch (ex) {
        this.show = false;
        console.log(ex, "Hata");
      }

      this.createSipModal = false;
    },
    async cancelOrder() {
      this.createSipModal = false;
    },
    async closeModal() {
      this.errorModal = false;
    },
    async etiketGetir() {
      var response = await this.$http.get(
        "v1/User/GetLabel?key=" + "B2BProformaHtml"
      );
      if (response.status == 200) {
        var etiketMetni = response.data.data;

        return etiketMetni;
      } else {
        return "HATA";
      }
    },
    async listele() {
      this.show = true;
      var request = {
        queryKey: "B2B_Urun_Listele",
        args: [
          {
            key: "@KRITER1",
            value: this.userData.musteriKod
          }
        ]
      };

      this.$http
        .post("v1/Dynamic/GetAllWithParams", request)
        .then(async response => {
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);

            satirlar.forEach(element => {
              element.EN = formatSayi(element.EN);
              element.BOY = formatSayi(element.BOY);
              element.YUKSEKLIK = formatSayi(element.YUKSEKLIK);
              element.M3 = element.M3.toFixed(5);
              element.SHOWBRUT = formatSayi(element.BRUT); //Gosterim için kullandım
              element.SHOWNET = formatSayi(element.NET); //Gosterim için kullandım
              element.TOPLAMNET = 0;
              element.TOPLAMBRUT = 0;
              element.TOPLAMM3 = 0;
              element.TOPLAMKOLI = 0;
              element.PALLET45HQ = 0;
              element.PALLET40HQ = 0;
              element.PALLETREEFER40HQ = 0;
              element.PALLET20DC = 0;
              element.DOKME = 0;
              element.PRICEDIT =
                element.PRICE.toString() + " " + element.PRICEUNIT2;
              element.LINK =
                "https://document.d7ksuper.com/Dokuman/linkal/ucantay/" +
                element.KOD.substring(0, 8) +
                ".png";
            });

            for (var i = 0; i < satirlar.length; i++) {
              let element = satirlar[i];
              let img = new Image();
              img.src = element.LINK;
              img.onload = function() {
                // Resim başarıyla yüklendi
              };
              //resim hata alırsa stok default resim gösteriyor
              img.onerror = function() {
                element.LINK =
                  "https://document.d7ksuper.com/Dokuman/linkal/ucantay/cosby.jpg";
              };
            }

            this.listDataAll = satirlar;

            this.rows = satirlar.length;

            this.show = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    async onayla() {
      this.rafModal = false;
      this.createSipModal = true;
    },
    async rafOmruSec() {
      this.rafModal = true;
    },
    async cariSec() {
      if (this.teslimTarihi == "" || this.teslimTarihi == null) {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Hatalı"),
            icon: "DangerIcon",
            variant: "danger",
            text: this.$t("Teslim Tarihi Seçili Değil")
          }
        });
        return;
      } else {
        if (this.sevkAdresleri.length > 1) {
          this.createSipModal = false;
          this.cariModal = true;
          console.log(this.sendMacroData);
        } else {
          this.cariModal = false;

          // var cariDefault = {
          //   SEVKIYATKOD: this.cariKontak.MUSTERIKODU,
          //   SEVKIYATAD: this.cariKontak.MUSTERIKODU_AD
          // };

          this.seciliSevkAdresi = this.cariKontak.MUSTERIKODU;
          this.createOrder();
        }
      }
    },
    async createProforma() {
      if (this.seciliurunlersay == 0 || this.seciliurunlersay == undefined) {
        this.$toast({
          component: ToastificationContent,
          position: "bottom-right",
          props: {
            title: this.$t("Hatalı"),
            icon: "DangerIcon",
            variant: "danger",
            text: this.$t("Lütfen Ürün Seçiniz !")
          }
        });
        return;
      }
      this.orderModal = true;
      this.teslimTarihi = "";
      this.createdDate = "";
      this.createdDate = moment(new Date()).format("YYYY/MM/DD");
      this.htmlTemp = await this.etiketGetir();
      this.etiketDuzenle(this.htmlTemp);
    },
    etiketDuzenle(object) {
      this.hataliKod = "";
      var proformaKapat = object.rowLabelData;
      var satirlar = this.listDataAll.filter(
        item =>
          item.PALLET40HQ > 0 ||
          item.PALLET45HQ > 0 ||
          item.PALLET20DC > 0 ||
          item.PALLETREEFER40HQ > 0 ||
          item.DOKME
      );

      for (var i = 0; i < satirlar.length; i++) {
        if ((satirlar[i].P1 == 0) & (satirlar[i].PALLET20DC > 0)) {
          this.hataliKod = satirlar[i].KOD;
          this.errorModal = true;
          return;
        }
        if ((satirlar[i].P2 == 0) & (satirlar[i].PALLET45HQ > 0)) {
          this.hataliKod = satirlar[i].KOD;
          this.errorModal = true;
          return;
        }
        if ((satirlar[i].P2 == 0) & (satirlar[i].PALLET40HQ > 0)) {
          this.hataliKod = satirlar[i].KOD;
          this.errorModal = true;
          return;
        }
        if ((satirlar[i].P2 == 0) & (satirlar[i].PALLETREEFER40HQ > 0)) {
          this.hataliKod = satirlar[i].KOD;
          this.errorModal = true;
          return;
        }
      }

      this.sendMacroData.satirBilgileri = satirlar;
      var satirTexts = "";
      var yenisatir = "";
      var subTotal = 0;
      var freight = 0;
      var priceUnit = "";
      var pcsTotal = 0;
      var totalM3 = 0;
      var totalNet = 0;
      var totalBrut = 0;
      var totalCartoon = 0;

      var satirlarArray = [];

      var openProforma = "";

      // her sayfa için 15 satır hesabı
      var say = 0;
      for (let i = 0; i < satirlar.length; i++) {
        say = say + 1;

        yenisatir =
          "<tr><td>@REF01</td><td>@REF02</td><td style='text-align:right !important'>@REF07</td><td style='text-align:right !important'>@REF03</td><td style='text-align:right !important'>@REF04</td><td style='text-align:right !important'>@REF05</td><td style='text-align:right !important'>@REF06</td></tr>";

        if (
          satirlar[i].MUSTERIURUNKOD == "" ||
          satirlar[i].MUSTERIURUNKOD == null
        ) {
          yenisatir = yenisatir.replaceAll(
            "@REF01",
            satirlar[i].KOD + " " + satirlar[i].AD
          );
        } else {
          yenisatir = yenisatir.replaceAll(
            "@REF01",
            satirlar[i].KOD +
              " " +
              "(" +
              satirlar[i].MUSTERIURUNKOD +
              ")" +
              " " +
              satirlar[i].AD
          );
        }

        yenisatir = yenisatir.replaceAll("@REF02", satirlar[i].INGACIKLAMA);
        var paletToplam = 0;
        paletToplam =
          Number(satirlar[i].PALLET20DC) +
          Number(satirlar[i].PALLET40HQ) +
          Number(satirlar[i].PALLET45HQ) +
          Number(satirlar[i].PALLETREEFER40HQ);
        //Karton Toplam
        yenisatir = yenisatir.replaceAll("@REF03", satirlar[i].TOPLAMKOLI);
        // Karton x Adet Toplam
        var toplamAdet =
          Number(satirlar[i].ADET_MIKTAR) * Number(satirlar[i].TOPLAMKOLI);
        yenisatir = yenisatir.replaceAll("@REF04", formatSayi(toplamAdet));
        // Koli başı fiyat
        var formatliFiyat = formatSayi(
          satirlar[i].PRICE,
          this.ozellestirilmisSecenekler
        );
        yenisatir = yenisatir.replaceAll(
          "@REF05",
          formatliFiyat + " " + satirlar[i].PRICEUNIT2
        );
        // Koli başı fiyat * koli adet
        var satirToplami =
          Number(satirlar[i].PRICE) * Number(satirlar[i].TOPLAMKOLI);
        var formatliToplam = formatSayi(
          satirToplami,
          this.ozellestirilmisSecenekler
        );
        yenisatir = yenisatir.replaceAll(
          "@REF06",
          formatliToplam + " " + satirlar[i].PRICEUNIT2
        );
        yenisatir = yenisatir.replaceAll("@REF07", satirlar[i].RAFOMRU);

        subTotal = (Number(subTotal) + Number(satirToplami)).toFixed(2);
        pcsTotal = Number(toplamAdet) + Number(pcsTotal);
        priceUnit = satirlar[i].PRICEUNIT2;

        satirTexts = satirTexts + yenisatir;

        if (say == 14 || i == satirlar.length - 1) {
          satirlarArray.push(satirTexts);
          satirTexts = "";
          say = 0;
        }
        // Satır M3 hesabını yapıyourm..
        totalM3 = Number(satirlar[i].TOPLAMM3) + Number(totalM3);
        totalNet = Number(satirlar[i].TOPLAMNET) + Number(totalNet);
        totalBrut = Number(satirlar[i].TOPLAMBRUT) + Number(totalBrut);
        totalCartoon = Number(satirlar[i].TOPLAMKOLI) + Number(totalCartoon);
      }

      proformaKapat = proformaKapat.replaceAll("@PCS", formatSayi(pcsTotal));
      proformaKapat = proformaKapat.replaceAll("@VOLUME", formatSayi(totalM3));
      proformaKapat = proformaKapat.replaceAll("@GROSS", formatSayi(totalBrut));
      proformaKapat = proformaKapat.replaceAll("@NET", formatSayi(totalNet));
      proformaKapat = proformaKapat.replaceAll(
        "@CARTONS",
        formatSayi(totalCartoon)
      );
      proformaKapat = proformaKapat.replaceAll(
        "@TOTALSUB",
        formatSayi(subTotal, this.ozellestirilmisSecenekler) + " " + priceUnit
      );
      proformaKapat = proformaKapat.replaceAll(
        "@TOTALGROSS",
        freight == 0 ? "" : freight
      );
      if (freight == 0) {
        proformaKapat = proformaKapat.replaceAll("Freight", "");
      }
      var totalGrand = Number(subTotal) + Number(freight);
      proformaKapat = proformaKapat.replaceAll(
        "@TOTALGRAND",
        formatSayi(totalGrand, this.ozellestirilmisSecenekler) + " " + priceUnit
      );

      for (let a = 0; a < satirlarArray.length; a++) {
        var proforma = object.labelData;
        proforma = proforma.replaceAll("@YeniSatir", satirlarArray[a]);

        if (a == satirlarArray.length - 1) {
          proforma = proforma.replaceAll("@AltKisim", proformaKapat);
        } else {
          proforma = proforma.replaceAll(
            "@AltKisim",
            "<h5 style='text-align:center !important'>Next Page</h5>"
          );
        }
        openProforma = openProforma + proforma;
      }

      openProforma = openProforma.replaceAll("@DATE", this.createdDate);
      openProforma = openProforma.replaceAll("@INVOICE", "");
      openProforma = openProforma.replaceAll("@CONTAINER", "");
      openProforma = openProforma.replaceAll("@DELIVERY", "");
      openProforma = openProforma.replaceAll("@SHIPMENT", "");
      openProforma = openProforma.replaceAll("@PAYMENT", "");

      openProforma = openProforma.replaceAll(
        "@ADRES",
        this.cariKontak.ADRES1 + " " + this.cariKontak.ADRES2
      );
      openProforma = openProforma.replaceAll(
        "@ALICI",
        this.cariKontak.MUSTERIKODU_AD
      );
      openProforma = openProforma.replaceAll("@EMAIL", this.cariKontak.EMAIL);
      openProforma = openProforma.replaceAll(
        "@TELEFON",
        this.cariKontak.TELEFON
      );
      openProforma = openProforma.replaceAll("@FAX", this.cariKontak.FAX);

      this.sendMacroData.evrakBilgileri.totalGross = totalBrut;
      this.sendMacroData.evrakBilgileri.totalNet = totalNet;
      this.sendMacroData.evrakBilgileri.totalPcs = pcsTotal;
      this.sendMacroData.evrakBilgileri.totalCarton = totalCartoon;
      this.sendMacroData.evrakBilgileri.totalVolume = totalM3;
      this.sendMacroData.evrakBilgileri.totalSub = subTotal;
      this.sendMacroData.evrakBilgileri.createdDate = this.createdDate;
      this.sendMacroData.evrakBilgileri.adres1 = this.cariKontak.ADRES1;
      this.sendMacroData.evrakBilgileri.adres2 = this.cariKontak.ADRES2;
      this.sendMacroData.evrakBilgileri.telefon = this.cariKontak.TELEFON;
      this.sendMacroData.evrakBilgileri.fax = this.cariKontak.FAX;
      this.sendMacroData.evrakBilgileri.email = this.cariKontak.EMAIL;
      this.sendMacroData.evrakBilgileri.musteriAd = this.cariKontak.MUSTERIKODU_AD;
      this.sendMacroData.evrakBilgileri.musteriKod = this.userData.musteriKod;

      this.etiketAc(openProforma);
    },
    etiketAc(proforma) {
      var disp_setting =
        "toolbar=no,location=no,directories=no,menubar=no, scrollbars=no,width=1000, height=2000";
      var myWindow = window.open("", "", disp_setting);
      myWindow.document.open();
      myWindow.document.write(proforma);
      var button = myWindow.document.createElement("button");
      button.innerHTML = "Create Order";
      button.className = "button";
      button.addEventListener("click", () => {
        myWindow.close();
        this.rafOmruSec();
      });

      var buttonx = myWindow.document.createElement("button");
      buttonx.innerHTML = "Cancel";
      buttonx.className = "buttonX";
      buttonx.addEventListener("click", () => {
        myWindow.close();
      });
      var body = myWindow.document.getElementsByTagName("body")[0];
      body.appendChild(button);
      body.appendChild(buttonx);

      myWindow.document.close();
      myWindow.focus();
    },
    onRowSelected(items) {
      this.rowSelected = items;
    },
    handleInputChange(tip) {
      var listDatas = this.listDataAll;

      if (tip == "PALLET20DC") {
        var toplamPALLET20DC = 0;
        for (var i = 0; i < listDatas.length; i++) {
          toplamPALLET20DC =
            Number(toplamPALLET20DC) + Number(listDatas[i].PALLET20DC);
        }

        this.pallet20dc = toplamPALLET20DC;
        if (this.pallet20dc == 0) {
          this.Toppallet20dc = 11;
        } else {
          this.Toppallet20dc = Math.ceil(this.pallet20dc / 11) * 11;
        }
        //Filtre çalışması için
        this.filteredPalet20;
      } else if (tip == "PALLETREEFER40HQ") {
        var toplamPALLETREEFER40HQ = 0;
        for (var i = 0; i < listDatas.length; i++) {
          toplamPALLETREEFER40HQ =
            Number(toplamPALLETREEFER40HQ) +
            Number(listDatas[i].PALLETREEFER40HQ);
        }
        this.palletref40hq = toplamPALLETREEFER40HQ;
        if (this.palletref40hq == 0) {
          this.Toppalletref40hq = 23;
        } else {
          this.Toppalletref40hq = Math.ceil(this.palletref40hq / 23) * 23;
        }
        this.filteredsogutmali40Hq;
      } else if (tip == "PALLET40HQ") {
        var toplamPALLET40HQ = 0;
        for (var i = 0; i < listDatas.length; i++) {
          toplamPALLET40HQ =
            Number(toplamPALLET40HQ) + Number(listDatas[i].PALLET40HQ);
        }
        this.pallet40hq = toplamPALLET40HQ;
        if (this.pallet40hq == 0) {
          this.Toppallet40hq = 24;
        } else {
          this.Toppallet40hq = Math.ceil(this.pallet40hq / 24) * 24;
        }
        this.filtered40Hq;
      } else if (tip == "PALLET45HQ") {
        var toplamPALLET45HQ = 0;
        for (var i = 0; i < listDatas.length; i++) {
          toplamPALLET45HQ =
            Number(toplamPALLET45HQ) + Number(listDatas[i].PALLET45HQ);
        }
        this.pallet45hq = toplamPALLET45HQ;
        if (this.pallet45hq == 0) {
          this.Toppallet45hq = 33;
        } else {
          this.Toppallet45hq = Math.ceil(this.pallet45hq / 33) * 33;
        }
        //Filtre çalışması için
        this.filteredPalet45;
      } else if (tip == "DOKME") {
        var dokmeTop = 0;
        for (var i = 0; i < listDatas.length; i++) {
          if (listDatas[i].DOKME == "") {
            listDatas[i].DOKME = 0;
          }
          dokmeTop = Number(dokmeTop) + Number(listDatas[i].DOKME);
        }
        this.toplamDokme = dokmeTop;

        //Filtre çalışması için
        this.filteredDokme;
      }

      for (var i = 0; i < listDatas.length; i++) {
        listDatas[i].TOPLAMKOLI =
          Number(listDatas[i].PALLET20DC) * Number(listDatas[i].P1) +
          Number(listDatas[i].PALLET40HQ) * Number(listDatas[i].P2) +
          Number(listDatas[i].PALLET45HQ) * Number(listDatas[i].P2) +
          Number(listDatas[i].PALLETREEFER40HQ) * Number(listDatas[i].P2) +
          Number(listDatas[i].DOKME);

        listDatas[i].TOPLAMM3 =
          Number(listDatas[i].TOPLAMKOLI) * Number(listDatas[i].M3);
        listDatas[i].TOPLAMBRUT =
          Number(listDatas[i].TOPLAMKOLI) * Number(listDatas[i].BRUT);
        listDatas[i].TOPLAMNET =
          Number(listDatas[i].TOPLAMKOLI) * Number(listDatas[i].NET);
      }
      this.listDataAll = listDatas;
      var secililer = this.listDataAll.filter(
        item =>
          item.DOKME > 0 ||
          item.PALLET40HQ > 0 ||
          item.PALLET45HQ > 0 ||
          item.PALLET20DC > 0 ||
          item.PALLETREEFER40HQ > 0
      );
      this.seciliurunlersay = secililer.length;
      var toplamtut = 0;
      var koliToplam = 0;
      var m3Toplam = 0;
      var brutToplam = 0;

      for (var a = 0; a < secililer.length; a++) {
        var tutar =
          Number(secililer[a].TOPLAMKOLI) * Number(secililer[a].PRICE);
        toplamtut = Number(toplamtut) + Number(tutar);

        koliToplam = Number(secililer[a].TOPLAMKOLI) + Number(koliToplam);

        m3Toplam = Number(secililer[a].TOPLAMM3) + Number(m3Toplam);
        brutToplam = Number(secililer[a].TOPLAMBRUT) + Number(brutToplam);
      }
      this.toplamTutar = formatSayi(toplamtut, this.ozellestirilmisSecenekler);
      this.toplamKoli = formatSayi(koliToplam);
      this.toplamM3 = formatSayi(m3Toplam, this.ozellestirilmisSecenekler);
      this.toplamBrut = formatSayi(brutToplam, this.ozellestirilmisSecenekler);

      if (secililer.length > 0) {
        this.tutarBirimi = secililer[0].PRICEUNIT2;
      }
    },
    showImageClick(data) {
      this.selectedImgUrl = data.item.LINK;
    },
    onFiltered(tip) {
      this.filter = tip;

      if (tip == "Filtre") {
        this.listData = this.listDataAll;
        this.listDataAll = this.listDataAll.filter(
          item =>
            item.PALLET40HQ > 0 ||
            item.PALLET45HQ > 0 ||
            item.PALLET20DC > 0 ||
            item.PALLETREEFER40HQ > 0 ||
            item.DOKME > 0
        );
        this.rows = this.listDataAll.length;
      } else {
        this.listDataAll = this.listData;
        this.rows = this.listDataAll.length;
      }
    },
    getRenkBGDOKME(DOKME) {
      if (DOKME > 0) {
        return "#f9ec01";
      } else if (DOKME == 0) {
        return "white";
      } else if (DOKME == null) {
        return "white";
      }
    },
    getRenkBG20DC(PALLET20DC) {
      if (PALLET20DC > 0) {
        return "#f9ec01";
      } else if (PALLET20DC == 0) {
        return "white";
      } else if (PALLET20DC == null) {
        return "white";
      }
    },
    getRenkBG40HQ(PALLETREEFER40HQ) {
      if (PALLETREEFER40HQ > 0) {
        return "#f9ec01";
      } else if (PALLETREEFER40HQ == 0) {
        return "white";
      } else if (PALLETREEFER40HQ == null) {
        return "white";
      }
    },
    getRenkBG40(PALLET40HQ) {
      if (PALLET40HQ > 0) {
        return "#f9ec01";
      } else if (PALLET40HQ == 0) {
        return "white";
      } else if (PALLET40HQ == null) {
        return "white";
      }
    },
    getRenkBG45(PALLET45HQ) {
      if (PALLET45HQ > 0) {
        return "#f9ec01";
      } else if (PALLET45HQ == 0) {
        return "white";
      } else if (PALLET45HQ == null) {
        return "white";
      }
    },
    async getContact() {
      var kriterler1 = {
        metin1: this.userData.musteriKod,
        queryKey: "B2BProformaCariBilgileriGetir"
      };
      await this.$http
        .post("v1/Dynamic/GetQueryReplaceData", kriterler1)
        .then(async response => {
          if (response.status == 200) {
            var satir = JSON.parse(response.data.data.dataJson);
            this.cariKontak = satir[0];

            this.kriterler2 = {
              metin1: this.userData.musteriKod,
              queryKey: "B2b_Sevk_Adresleri_Getir"
            };
            await this.$http
              .post("v1/Dynamic/GetQueryReplaceData", this.kriterler2)
              .then(response => {
                this.show = false;
                if (response.status == 200) {
                  var satirlar = JSON.parse(response.data.data.dataJson);
                  this.sevkAdresleri = satirlar;
                  var ilkCari = {
                    SEVKIYATKOD: this.cariKontak.MUSTERIKODU,
                    SEVKIYATAD: this.cariKontak.MUSTERIKODU_AD
                  };
                  this.sevkAdresleri.push(ilkCari);
                }
              })
              .catch(error => {
                this.show = false;
                console.log(error, "Hata Sevk Adresleri");
              });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    async closePopup() {
      this.tModal = false;
    },
    async rafOmruGetir() {
      this.kriterler1 = {
        queryKey: "B2b_Raf_Omru_Getir"
      };
      await this.$http
        .post("v1/Dynamic/GetQueryReplaceData", this.kriterler1)
        .then(response => {
          this.show = false;
          if (response.status == 200) {
            var satirlar = JSON.parse(response.data.data.dataJson);

            this.rafOmurleri = satirlar;
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error, "Hata Raf");
        });
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style >
.table {
  border-collapse: inherit !important;
}
.table .productButton {
  width: 90px !important;
  margin: 0px !important;
  padding: 0px !important;
}
.table .productKod {
  text-align: center !important;
  width: 130px !important;
}
.table .productDay {
  text-align: center !important;
  width: 110px !important;
}
.table .productAdet {
  text-align: center;
  width: 80px !important;
  color: black;
}
.table .productPrice {
  text-align: right;
  width: 120px !important;
  max-width: 120px !important;
  color: black;
}

.table .productP1 {
  text-align: center !important;
  width: 60px !important;
  color: black;
}
.table .productP2 {
  text-align: center !important;
  width: 60px !important;
  color: black;
}
.table .productEn {
  text-align: center;
  width: 60px !important;
  color: black;
}
.table .productBoy {
  text-align: center;
  width: 60px !important;
  color: black;
}
.table .productYukseklik {
  text-align: center;
  width: 60px !important;
  color: black;
}
.table .productM3 {
  text-align: right;
  width: 60px !important;
  color: black;
}
.table .productBrut {
  text-align: right;
  width: 60px !important;
  color: black;
}

.table .productNet {
  text-align: right;
  width: 60px !important;
  color: black;
}
.table .productUnit {
  width: 130px !important;
  max-width: 150px !important;
  color: black;
}
.table .dokme {
  width: 130px !important;
}
.table .pallet20DC {
  width: 130px !important;
}
/* #F9EC01 */
.table .palletReefer40HQ {
  width: 130px !important;
}
.table .pallet40HQ {
  width: 130px !important;
}
.table .pallet45HQ {
  width: 130px !important;
}
#headerText {
  display: flex !important;
  justify-content: center !important; /* Yatay ortala */
  text-align: center !important;
  font-weight: normal !important;
  color: white !important;
}

.fixed-card {
  width: 100% !important;
  bottom: 15px !important;
  max-height: 75px !important;
  padding: 0px !important;
}

#row-card {
  height: 50px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: #cecece;
}

.row-fixed {
  position: fixed;
  bottom: 10px;
  cursor: pointer;
  width: 99%;
  height: 60px;
  padding: 0px !important;
}

.b-table-sticky-header {
  max-height: 71vh !important;
  border-radius: 5px !important;
}

.th-group {
  display: flex; /* İç içe geçen öğeleri yatay hizala */
  align-items: center; /* Öğeleri dikey olarak ortala */
}

.productP1,
.productP2 {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  text-align: center;
}

.productM3,
.productBrut,
.productNet {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  text-align: center;
}
</style>
